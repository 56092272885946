import React, { useState, useEffect } from 'react'
import TopHeader from '../../Components/TopHeader';
import Prince from '../../Components/Prince';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';



const NewLeads = () => {
  const [statusFilter, setStatusFilter] = useState('');
  const [search, setSearch] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { status } = useParams();
  const navigate = useNavigate();
  const initialFormData = {
    excelFile: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [leadCounts, setLeadCounts] = useState({
    newLead: 0,
    Followup: 0,

  });
  const [users, setUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(localStorage.getItem('activeStatus') || '');

  const navigation = useNavigate();
  const Token = localStorage.getItem("Token");
  const apiUrl = process.env.REACT_APP_URL;

  const handleOpenModal4 = () => {
    setIsModalOpen4(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal4 = () => {
    setIsModalOpen4(false);
    document.body.classList.remove('modal-open');
  };
  const handleStatusChange = (status) => {
    setActiveStatus(`/newCom/${status}`);
    localStorage.setItem('activeStatus', `/newCom/${status}`);
    // setRefresh((prevRefresh) => !prevRefresh);

  };

  useEffect(() => {
    handleStatusChange(status);
  }, [status])


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Check if file is selected
    if (file) {
      // Check if file type is correct
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFormData({ ...formData, excelFile: file });
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Append the file to formDataToSend
      formDataToSend.append('excelFile', formData.excelFile);

      const response = await fetch(`${apiUrl}/lead/uploadExcel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      handleCloseModal4()
      setRefresh(!refresh);
      setFormData(initialFormData); // Reset the form
      alert(response2.message);

    } catch (error) {
      alert(error.message);

    }
  };


  // ledes list api  
  const fetchData = async () => {
    try {
      const Token = localStorage.getItem('Token');
      const response = await fetch(`${apiUrl}/lead/getAllLead`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      });

      const data = await response.json();
      setLeadCounts({
        newLead: data.newLead,
        Followup: data.Followup,

      });

      if (data.status === 'success' && Array.isArray(data.data)) {
        setUsers(data.data);
        setRefresh(!refresh);

      } else {
        console.error('API request was not successful or data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);


  const filteredUsers = users.filter((user) => {
    return (
      (user.id.toString().includes(search) || user.fullName.includes(search)) &&
      (statusFilter === '' || user.status.toString() === statusFilter) &&
      (genderFilter === '' || user.gender === genderFilter || (genderFilter === 'male' && user.gender === 'Male') || (genderFilter === 'female' && user.gender === 'Female'))
    );
  });


  useEffect(() => {
    const token = localStorage.getItem('Token');

    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);

  return (
    <>

      {/* Main Header*/}
      <div className="page ">
        <TopHeader />
        <Prince />


        {/* Main Content*/}
        <div className="main-content  pt-0 ">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">All Leads</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">CRM </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      New Lead{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <Link
                      to=""
                      onClick={handleOpenModal4}

                      type="button"
                      className="btn btn-primary my-2 btn-icon-text me-2"

                    >
                      <i className="fe fe-upload me-2" /> Bulk Upload
                    </Link>



                    <div
                      className={`modal ${isModalOpen4 ? 'show' : ''}`}
                      style={{ display: isModalOpen4 ? 'block' : 'none' }}
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog modal-dialog-centered modal-sl" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Import Leads</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModal4}
                            >
                              <span aria-hidden="true">&times;</span>

                            </button>

                          </div>

                          <div className="modal-body">
                            <form>
                              <div className="row row-sm">
                                <div className="col-sm-12 form-group">
                                  <label className="form-label">File Picker</label>
                                  <input
                                    type="file"
                                    accept=".xls, .xlsx"
                                    onChange={handleFileChange}
                                  />
                                </div>

                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button className="btn ripple btn-primary" fetchLeadData type="button"
                              onClick={handleSubmit}>
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Link
                      to="https://amrsapi.webkype.co/uploads/leadAmrs%20(sample).xlsx"
                      download
                      type="button"
                      className="btn btn-primary my-2 btn-icon-text me-2"

                    >
                      <i className="fe fe-download me-2" /> Download Sample Exl
                    </Link>
                    <Link
                      to='/add-sales-lead'
                      className="btn btn-primary my-2 btn-icon-text"

                    >
                      <i className="fe fe-plus me-2" /> Add New Lead
                    </Link>




                  </div>
                </div>
                
              </div>

            </div>
          </div>
          
        </div>
        {/* End Page Header */}

        {/* Row */}
        

        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div className="text-wrap">
                  <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
                    <div className="tab-menu-heading">
                      <div className=" ">
                        {/* Tabs */}
                        <ul className="nav panel-tabs horizontal_tab">
                          <li className="">
                            <Link
                              to="/newCom/New Lead"
                              className={activeStatus === '/newCom/New Lead' ? 'active' : ''}
                              onClick={() => handleStatusChange('New Lead')}
                            >
                              New ({leadCounts.newLead})
                            </Link>
                          </li>

                          {/* <li>
                            <Link
                              to="/newCom/Assigned"
                              className={activeStatus === '/newCom/Assigned' ? 'active' : ''}
                              onClick={() => handleStatusChange('Assigned')}
                            >
                              Assigned ({leadCounts.assigned})
                            </Link>
                          </li> */}

                          <li>
                            <Link
                              to="/newCom/Follow up"
                              className={activeStatus === '/newCom/Follow up' ? 'active' : ''}
                              onClick={() => handleStatusChange('Follow up')}
                            >
                              Followup ({leadCounts.Followup})
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/newCom/Quotations"
                              className={activeStatus === '/newCom/Quotations' ? 'active' : ''}
                              onClick={() => handleStatusChange('Quotations')}
                            >
                              Meeting Done ({leadCounts.quotations})
                            </Link>

                          </li>
                          <li>
                            <Link
                              to="/newCom/Pre-proforma"
                              className={activeStatus === '/newCom/Pre-proforma' ? 'active' : ''}
                              onClick={() => handleStatusChange('Pre-proforma')}
                            >
                              preProforma ({leadCounts.preProforma})
                            </Link>
                          </li>

                          <li>

                            <Link to="/newCom/Convertedtosale"
                              className={activeStatus === '/newCom/Convertedtosale' ? 'active' : ''}
                              onClick={() => handleStatusChange('Convertedtosale')}>

                              Convertedtosale ({leadCounts.convertToSale})

                            </Link>

                          </li>

                          <li>
                            <Link
                              to="/newCom/LOST"
                              className={activeStatus === '/newCom/LOST' ? 'active' : ''}
                              onClick={() => handleStatusChange('LOST')}
                            >
                              LOST  ({leadCounts.lost})
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div >
    </>

  )
}

export default NewLeads