import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const SingalPaymentLadger = () => {
    const { empid } = useParams();


    const initialFormData = {
        amount: '',
        collectionMode: '',
        collectionMode: '',
        enterDiscount: '',
        paymentCredit: '',
        handOverBy: '',
        collectedBy: '',
        uploadRecipt: '',
        select: '',
        amrsAccount: '',
        transactionNo: '',
        chequeDate: '',
        chequeNo: '',
        bankCheque: '',
        deposteToAmrs: '',
        gift: '',


    };
    const [formData, setFormData] = useState(initialFormData);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [project, setProject] = useState([]);
    const [users, setUsers] = useState([]);
    const [employee, setEmployee] = useState({})
    const [employee3, setEmployee3] = useState({})
    const [employee4, setEmployee4] = useState({})
    const [disableInput2, setDisableInput2] = useState(true);
    const [holdStatus, setHoldStatus] = useState({});
    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const [holdActionId, setHoldActionId] = useState(null);
    const [paidAmount, setPaidAmount] = useState(0);
    const [employee2, setEmployee2] = useState({})
    const [users2, setUsers2] = useState([]);
    const [users3, setUsers3] = useState([]);
    const [users4, setUsers4] = useState([]);
    const [reportingBossA, setReportingBossA] = useState([])
    const [total1, setTotal1] = useState('');
    const [total2, setTotal2] = useState('');
    const [total3, setTotal3] = useState('');
    const [total4, setTotal4] = useState('');

    const [total5, setTotal5] = useState('');
    const [total6, setTotal6] = useState('');
    const [total7, setTotal7] = useState('');
    const [total8, setTotal8] = useState('');
    const [total9, setTotal9] = useState('');
    const [total10, setTotal10] = useState('');
    const [total11, setTotal11] = useState('');
    const [total12, setTotal12] = useState('');
    const [total13, setTotal13] = useState('');
    const [total14, setTotal14] = useState('');
    const [ins, setIns] = useState('');
    const [isModalOpen16, setIsModalOpen16] = useState(false);
    const [gift, setGift] = useState([])
    const apiUrl = process.env.REACT_APP_URL;
    const Token = localStorage.getItem("Token");

    // fpp plan
    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.onBookingPerFPP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal1(formattedPercentageValue);


        } else {

            setTotal1(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.onBookingPerFPP]);

    useEffect(() => {
        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(total1);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            const totalValue = (areaValue - priceValue) / 24;
            let formattedTotalValue = totalValue.toFixed(2);
            if (formattedTotalValue.endsWith('.00')) {
                formattedTotalValue = formattedTotalValue.slice(0, -3);
            }
            setIns(formattedTotalValue);
            console.log('Total (divided by 24 months):', formattedTotalValue);
        } else {
            setIns(null);
        }
    }, [employee4.totalCost, total1]);

    //DLP plan

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.onBookingPerDLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal2(formattedPercentageValue);


        } else {

            setTotal2(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.onBookingPerDLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.withIn30PerDLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal3(formattedPercentageValue);


        } else {

            setTotal3(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.withIn30PerDLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.restOnRegistryPerDLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal4(formattedPercentageValue);


        } else {

            setTotal4(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.restOnRegistryPerDLP]);

    //PLP Plan

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.onBookingPerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal5(formattedPercentageValue);


        } else {

            setTotal5(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.onBookingPerPLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.withIn60PerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal6(formattedPercentageValue);


        } else {

            setTotal6(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.withIn60PerPLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.withIn90PerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal7(formattedPercentageValue);


        } else {

            setTotal7(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.withIn90PerPLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.withIn120PerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal8(formattedPercentageValue);


        } else {

            setTotal8(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.withIn120PerPLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.withIn150PerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal9(formattedPercentageValue);


        } else {

            setTotal9(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.withIn150PerPLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.withIn180PerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal10(formattedPercentageValue);


        } else {

            setTotal10(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.withIn180PerPLP]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.extraPerPLP1);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal11(formattedPercentageValue);


        } else {

            setTotal11(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.extraPerPLP1]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.extraPerPLP2);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal12(formattedPercentageValue);


        } else {

            setTotal12(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.extraPerPLP2]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.extraPerPLP3);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal13(formattedPercentageValue);


        } else {

            setTotal13(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.extraPerPLP3]);

    useEffect(() => {

        const areaValue = parseFloat(employee4.totalCost);
        const priceValue = parseFloat(employee4 && employee4.plan && employee4.plan.restOnRegistryPerPLP);

        if (!isNaN(areaValue) && !isNaN(priceValue)) {
            // Calculate the percentage
            const percentageValue = areaValue * (priceValue / 100);

            // Format the percentage value
            let formattedPercentageValue = percentageValue.toFixed(2);
            if (formattedPercentageValue.endsWith('.00')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -3);
            } else if (formattedPercentageValue.endsWith('0')) {
                formattedPercentageValue = formattedPercentageValue.slice(0, -1);
            }

            setTotal14(formattedPercentageValue);


        } else {

            setTotal14(null);
        }
    }, [employee4.totalCost, employee4 && employee4.plan && employee4.plan.restOnRegistryPerPLP]);


    const handleOpenModal16 = () => {
        setIsModalOpen16(true);
        document.body.classList.add('modal-open');
    };

    const handleCloseModal16 = () => {
        setIsModalOpen16(false);
        document.body.classList.remove('modal-open');
    };

    useEffect(() => {
        if (isModalOpen16) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen16]);

    const handleOpenModal3 = () => {
        setIsModalOpen3(true);
        document.body.classList.add('modal-open');
    };
    const handleCloseModal3 = () => {
        setIsModalOpen3(false);
        document.body.classList.remove('modal-open');
    };

    const handleOpenModal4 = (id) => {
        setHoldActionId(id);
        setIsModalOpen4(true);
    };

    const handleCloseModal4 = () => {
        setIsModalOpen4(false);
        setHoldActionId(null);
    };

    async function getEmppps() {
        const url = `${apiUrl}/applicant/getApplicantInfo/${empid}`;
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${Token}`
            },
        });
        response = await response.json();

        if (response.status === "success") {
            setEmployee4(response.data);
        }
    }

    useEffect(() => {
        getEmppps();
    }, []);


    useEffect(() => {
        async function getEmpppss() {

            const url = `${apiUrl}/applicant/getApplicantInfo/${empid}`;
            let response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${Token}`
                },
            })
            response = await response.json();

            if (response.status === "success") {
                setEmployee2(response.data.plan.id);
            }
        }
        getEmpppss();
    }, [])

    // bank api  
    useEffect(() => {
        fetch(`${apiUrl}/bank/accountDropdown`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setProject(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, []);


    //     const fetchUser = async () => {

    //         try {
    //             const url = `${apiUrl}/plan/planById/${employee2}`;
    //             console.log(url)
    //             let result = await fetch(url, {
    //                 headers: {
    //                     Authorization: `Bearer ${Token}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //             });

    //             result = await result.json();
    //             const { data } = result;


    //             setFormData((prevFormData) => ({
    //                 ...prevFormData,

    //                 brocehureImage: data.brocehureImage,
    //                 paymentPlanImage: data.paymentPlanImage,
    //                 schemeId: data.schemeId,
    //                 projectId: data.projectId,
    //                 companyId: data.companyId,
    //                 planDescription: data.planDescription,
    //                 basicPriceFPP: data.basicPriceFPP,
    //                 onBookingPerFPP: data.onBookingPerFPP,
    //                 onBookingFPP: data.onBookingFPP,
    //                 installMentFPP: data.installMentFPP,
    //                 totalValuePerFPP: data.totalValuePerFPP,
    //                 totalValueFPP: data.totalValueFPP,
    //                 createdAt: data.createdAt,
    //                 basicPricePLP: data.basicPricePLP,
    //                 onBookingPerPLP: data.onBookingPerPLP,
    //                 onBookingPLP: data.onBookingPLP,
    //                 withIn60PerPLP: data.withIn60PerPLP,
    //                 withIn60PLP: data.withIn60PLP,
    //                 withIn90PerPLP: data.withIn90PerPLP,
    //                 withIn90PLP: data.withIn90PLP,
    //                 withIn120PerPLP: data.withIn120PerPLP,
    //                 withIn120PLP: data.withIn120PLP,
    //                 withIn150PerPLP: data.withIn150PerPLP,
    //                 withIn150PLP: data.withIn150PLP,
    //                 withIn180PerPLP: data.withIn180PerPLP,
    //                 withIn180PLP: data.withIn180PLP,
    //                 restOnRegistryPerPLP: data.restOnRegistryPerPLP,
    //                 restOnRegistryPLP: data.restOnRegistryPLP,
    //                 basicPriceDLP: data.basicPriceDLP,
    //                 onBookingPerDLP: data.onBookingPerDLP,
    //                 onBookingDLP: data.onBookingDLP,
    //                 withIn30PerDLP: data.withIn30PerDLP,
    //                 withIn30DLP: data.withIn30DLP,
    //                 restOnRegistryPerDLP: data.restOnRegistryPerDLP,
    //                 restOnRegistryDLP: data.restOnRegistryDLP,
    //                 totalValuePerDLP: data.totalValuePerDLP,
    //                 totalValueDLP: data.totalValueDLP,

    //                 extraPLP1: data.extraPLP1,
    //                 extraPerPLP1: data.extraPerPLP1,
    //                 extraValuePLP1: data.extraValuePLP1,
    //                 companyPhoto: data.companyPhoto,
    //                 extraPLP2: data.extraPLP2,
    //                 extraPerPLP2: data.extraPerPLP2,
    //                 extraValuePLP2: data.extraValuePLP2,

    //                 extraPLP3: data.extraPLP3,
    //                 extraPerPLP3: data.extraPerPLP3,
    //                 extraValuePLP3: data.extraValuePLP3,

    //                 totalPerPLP: data.totalPerPLP,
    //                 totalValuePLP: data.totalValuePLP,
    //                 note: data.note,

    //                 areaFPP: data.areaFPP,
    //                 areaUnitFPP: data.areaUnitFPP,

    //                 areaPLP: data.areaPLP,
    //                 areaUnitPLP: data.areaUnitPLP,

    //                 areaDLP: data.areaDLP,
    //                 areaUnitDLP: data.areaUnitDLP,

    //             }));

    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchUser();
    // }, []);

    //Boss a

    useEffect(() => {
        const Token = localStorage.getItem('Token');
        console.log('Token:', Token);

        fetch(`${apiUrl}/employee/allEmpDesig`, {
            headers: {
                'Authorization': `Bearer ${Token}`

            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setReportingBossA(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching gender options:', error);
            });
    }, []);


    useEffect(() => {
        async function getEmp() {

            const Token = localStorage.getItem("Token");
            let response = await fetch(`${apiUrl}/employee/employee`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`
                },
            })
            response = await response.json();

            if (response.status === "success") {
                setEmployee(response.data);
            }
        }
        getEmp();
    }, [])

    const handleInputChange2 = (e) => {
        if (!disableInput2) {
            setFormData({ ...formData, basicPriceFPP: e.target.value });
        }
    };


    const handleFileChange15 = (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            const applicantImageFile = files[0];

            if (applicantImageFile.type.startsWith('image/')) {


                setFormData((prevData) => ({
                    ...prevData,
                    uploadRecipt: applicantImageFile,
                }));
            } else if (applicantImageFile.type === 'application/pdf') {
                // Handle PDF files
                setFormData((prevData) => ({
                    ...prevData,
                    uploadRecipt: applicantImageFile,

                }));
            } else {
                console.log('Unsupported file type');
            }
        } else {
            console.log('No file selected');
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();

            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/payment/addPayment?applicantId=${empid}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();

            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            handleCloseModal3()
            fetchDataFromApi()
            getEmppps()
            setFormData(initialFormData);
            toast.success(response2.message);


        } catch (error) {
            toast.error(error.message);

        }
    };

    //gift api 
    useEffect(() => {
        fetch(`${apiUrl}/gift/giftDropdown`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setGift(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, []);

    //hold or unhold status
    useEffect(() => {
        const holdStatusFromStorage = localStorage.getItem('holdStatus');
        if (holdStatusFromStorage) {
            const holdStatus = JSON.parse(holdStatusFromStorage);
            setHoldStatus(holdStatus);
        }
    }, []);

    const toggleHoldStatus = (id) => {
        const newHoldStatus = { ...holdStatus };
        newHoldStatus[id] = !newHoldStatus[id];
        setHoldStatus(newHoldStatus);
        localStorage.setItem('holdStatus', JSON.stringify(newHoldStatus));
    };

    useEffect(() => {
        // Prevent scrolling when modal is open
        if (isModalOpen3) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen3]);


    useEffect(() => {
        // Prevent scrolling when modal is open
        if (isModalOpen4) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen4]);

    const fetchDataFromApis = (id) => {
        const isUnhold = !holdStatus[id];
        const url = `${apiUrl}/payment/modifyPaymentLedger?paymentId=${id}&isUnhold=${isUnhold}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setEmployee3(data.data);
                    toggleHoldStatus(id);
                    const button = document.getElementById(`unholdButton_${id}`);
                    if (button) {
                        button.textContent = isUnhold ? 'Hold' : 'Unhold';
                        button.classList.toggle('btn-primary', !isUnhold);
                        button.classList.toggle('btn-success', isUnhold);
                    }
                } else {
                    console.error('API request was not successful:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const formatTime2 = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return date.toLocaleTimeString('en-IN', options);
    };

    const fetchDataFromApisss = () => {
        fetch(`${apiUrl}/applicant/getUpcomingPayment?applicantId=${empid}`, {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setPaidAmount(data.paidAmount);
                    if (Array.isArray(data.data)) {
                        const formattedData = data.data.map(item => ({
                            ...item,
                            date: item.date ? formatDateTime(item.date) : null,
                            time: item.time ? formatTime2(item.time) : null,
                        }));
                        setUsers2(formattedData);
                    } else {
                        console.error('API response does not contain companyList array:', data);
                    }
                } else {
                    console.error('API request was not successful:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchDataFromApisss()
    }, []);


    const handleOkButtonClick = () => {
        if (holdActionId !== null) {
            fetchDataFromApis(holdActionId);
            handleCloseModal4();
        }
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const formatDateTimes = (dateTimeString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateTimeString);
        return date.toLocaleString('en-IN', options);
    };
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const formatDateTime2 = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    // liST Payment
    const fetchDataFromApi = () => {
        fetch(`${apiUrl}/payment/getPayment?applicantId=${empid}`, {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setPaidAmount(data.paidAmount);
                    if (Array.isArray(data.data)) {
                        const formattedData = data.data.map(item => ({
                            ...item,
                            formattedDate: item.chequeDate ? formatDateTime(item.chequeDate) : null,
                            formattedDates: item.createdAt ? formatDateTimes(item.createdAt) : null,
                            formattedDate2: item.paymentCredit ? formatDateTime2(item.paymentCredit) : null,
                            bankName: item.amrsAccount ? item.amrsAccount.bankName : null,
                            accountNumber: item.amrsAccount ? item.amrsAccount.accountNumber : null,
                            bankName: item.amrsAccount ? item.amrsAccount.bankName : null,

                        }));

                        setUsers(formattedData);
                    } else {
                        console.error('API response does not contain companyList array:', data);
                    }
                } else {
                    console.error('API request was not successful:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchDataFromApi()
    }, []);

    // liST SchemePyament
    const fetchSchemePyament = () => {
        fetch(`${apiUrl}/applicant/getSchemePayment?applicantId=${empid}`, {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setPaidAmount(data.paidAmount);
                    if (Array.isArray(data.data)) {
                        const formattedData = data.data.map(item => ({
                            ...item,
                            formattedDate: item.chequeDate ? formatDateTime(item.chequeDate) : null,
                            formattedDates: item.createdAt ? formatDateTimes(item.createdAt) : null,
                            formattedDate2: item.paymentCredit ? formatDateTime2(item.paymentCredit) : null,
                            bankName: item.amrsAccount ? item.amrsAccount.bankName : null,
                            accountNumber: item.amrsAccount ? item.amrsAccount.accountNumber : null,
                        }));

                        setUsers3(formattedData);
                    } else {
                        console.error('API response does not contain companyList array:', data);
                    }
                } else {
                    console.error('API request was not successful:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchSchemePyament()
    }, []);


    const fetchSchemePyaments = () => {
        fetch(`${apiUrl}/applicant/paymentSchedule?applicantId=${empid}`, {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setPaidAmount(data.paidAmount);
                    if (Array.isArray(data.data)) {
                        const formattedData = data.data.map(item => ({
                            ...item,
                            paymentDate: item.paymentDate ? formatDateTime(item.paymentDate) : null,

                        }));

                        setUsers4(formattedData);
                    } else {
                        console.error('API response does not contain companyList array:', data);
                    }
                } else {
                    console.error('API request was not successful:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchSchemePyaments()
    }, []);


    return (
        <>
            <div className="page">
                <TopHeader />
                <Prince />

                <div className="main-content  pt-0" >
                    <div className="main-container container-fluid">
                        <div className="page-header">
                            {/* Page Header */}
                            <div className="page-header" style={{ width: '100%', margin: '0 auto' }}>
                                <div className="row row-sm" style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', gap: '1rem' }}>
                                    {/* Card 1 */}
                                    <div className="col-sm-12 col-md-2" style={{ flex: '1 1 18%', minWidth: '250px', maxWidth: '250pxpx' }}>
                                        <div className="card custom-card" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                                            <div className="card-body" style={{ padding: '1rem' }}>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>CID: {employee4.ticketId}</h6>
                                                <h6 style={{ whiteSpace: 'nowrap', margin: '0', paddingBottom: '8px' }}>
                                                    <span style={{ fontSize: '1rem', marginRight: '8px' }}>{employee4.applicantFirstName} {employee4.applicantLastName}</span>
                                                    <span className="badge bg-success" style={{ fontSize: '0.75rem' }}>Customer</span>
                                                </h6>
                                                <span className="text-muted">Phone: {employee4.applicantMobile}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 2 */}
                                    <div className="col-sm-12 col-md-2" style={{ flex: '1 1 18%', minWidth: '250px', maxWidth: '250px' }}>
                                        <div className="card custom-card" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                                            <div className="card-body" style={{ padding: '1rem' }}>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>Project: {employee4.projectId}</h6>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>
                                                    <span style={{ fontSize: '1rem', marginRight: '8px' }}>Unit: {employee4.unitNo}</span>
                                                    <span className="badge bg-danger" style={{ fontSize: '0.75rem' }}>Unit</span>
                                                </h6>
                                                <span className="text-muted">Type: {employee4.schemeType}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 3 */}
                                    <div className="col-sm-12 col-md-2" style={{ flex: '1 1 18%', minWidth: '250px', maxWidth: '250px' }}>
                                        <div className="card custom-card" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                                            <div className="card-body" style={{ padding: '1rem' }}>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>Total Amount</h6>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>
                                                    <span style={{ fontSize: '1rem', color: 'blue', marginRight: '8px' }}>{employee4.totalCost || '0'}</span>
                                                </h6>
                                                <span className="text-muted">Final Payable ({employee4.paymentPlan})</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 4 */}
                                    <div className="col-sm-12 col-md-2" style={{ flex: '1 1 18%', minWidth: '250px', maxWidth: '250px' }}>
                                        <div className="card custom-card" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                                            <div className="card-body" style={{ padding: '1rem' }}>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>Total Due</h6>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>
                                                    <span style={{ fontSize: '1rem', color: 'red', marginRight: '8px' }}>{employee4.dueAmount || '0'}</span>
                                                </h6>
                                                <span className="text-muted">Final Due</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 5 */}
                                    <div className="col-sm-12 col-md-2" style={{ flex: '1 1 18%', minWidth: '250px', maxWidth: '250px' }}>
                                        <div className="card custom-card" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                                            <div className="card-body" style={{ padding: '1rem' }}>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>Total Received</h6>
                                                <h6 style={{ margin: '0', paddingBottom: '8px' }}>
                                                    <span style={{ fontSize: '1rem', color: 'green', marginRight: '8px' }}>{employee4.totalReceived || '0'}</span>
                                                </h6>
                                                <span className="text-muted">Final Received</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div style={{ width: '100%', marginTop: '10px', backgroundColor: 'var(--primary-bg-color)', padding: '5px 20px' }}>
                                <h5 style={{ textAlign: 'left', color: '#ffffff', fontSize: '-10rem', margin: '0' }}>Payment History</h5>
                            </div>

                            <div className="row row-sm" style={{ width: '101.5%' }}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card main-content-body-profile"
                                        style={{
                                            width: "100%",  // Full width for the card
                                            margin: "0 auto",  // Center it on the screen
                                            padding: "20px",
                                        }}>
                                        <div
                                            className="scroll-container"
                                            style={{
                                                overflowX: "auto", // Enable horizontal scroll if necessary
                                                maxWidth: "100%",
                                            }}
                                        >
                                            <style>
                                                {`
                        .scroll-container::-webkit-scrollbar {
                            height: 6px; /* Customize the scrollbar height */
                        }
                        .scroll-container::-webkit-scrollbar-thumb {
                            background-color: #888; /* Customize scrollbar color */
                            border-radius: 10px;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            table-layout: auto; /* Let the table adapt */
                        }
                        th, td {
                            padding: 6px 8px;
                            font-size: 14px;
                            text-align: center;
                            white-space: nowrap; /* Prevent text wrapping */
                        }
                        th {
                            background-color: #f2f2f2;
                            font-weight: bold;
                            font-size: 14px;
                        }
                        .left-aligned {
                            text-align: left;
                            padding-left: 8px;
                        }
                        /* Avoid setting fixed widths */
                        th:nth-child(10), td:nth-child(10) {
                            min-width: 200px;  /* Ensure Actions Performed By has enough space */
                        }
                        @media (max-width: 1200px) {
                            /* Responsive adjustments for smaller screens */
                            table {
                                width: 100%;
                            }
                            th, td {
                                font-size: 12px;
                                padding: 4px 6px;
                            }
                        }
                    `}
                                            </style>
                                            <table align="center" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Amount Entry</th>
                                                        <th>Payment Mode</th>
                                                        <th>Method</th>
                                                        <th>Bank Name</th>
                                                        <th>AMRS Account</th>
                                                        <th>CHQ/TRN No</th>
                                                        <th>Cheque Date</th>
                                                        <th>Payment Date</th>
                                                        <th>Actions</th>
                                                        <th>Actions Performed By</th> {/* Dynamically sized column */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.length > 0 ? (
                                                        users.map((user) => (
                                                            <tr key={user.id}>
                                                                <td>{user.amount}</td>
                                                                <td>{user.collectionMode}</td>
                                                                <td>{user.collectionMode === 'Online' ? user.select : 'Cash'}</td>
                                                                <td>{user.bankName || 'N/A'}</td>
                                                                <td>{user.accountNumber || 'N/A'}</td>
                                                                <td>{user.transactionNo || user.chequeNo}</td>
                                                                <td>{user.formattedDate || 'N/A'}</td>
                                                                <td>{user.formattedDate2 || 'N/A'}</td>
                                                                <td>
                                                                    <Link to={`/view-receipt/${user.id}`} target='_blank' className="btn ripple btn-primary btn-xs" style={{ marginRight: "6px", fontSize: "12px" }}>
                                                                        View Receipt
                                                                    </Link>
                                                                    <button
                                                                        type="button"
                                                                        className={`btn ripple btn-${holdStatus[user.id] ? 'success' : 'primary'} btn-xs`}
                                                                        style={{ marginRight: "6px", fontSize: "12px" }}
                                                                        onClick={() => handleOpenModal4(user.id)}
                                                                    >
                                                                        {holdStatus[user.id] ? 'Hold' : 'Unhold'}
                                                                    </button>
                                                                    <button className="btn ripple btn-primary btn-xs" style={{ marginRight: "6px", fontSize: "12px" }}>
                                                                        Delete
                                                                    </button>
                                                                    <i className="fa fa-edit" style={{ cursor: 'pointer', fontSize: '14px' }} />
                                                                </td>
                                                                <td>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <div style={{ width: "20px", height: "30px", borderRadius: "50%", overflow: "hidden", marginRight: "5px" }}>
                                                                            <img src={employee.profilePhoto || 'https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-512.png'} alt="Profile" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                                                        </div>
                                                                        <div>
                                                                            {employee.fullName}
                                                                            <br />
                                                                            {user.formattedDates}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="10">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td className="left-aligned" colSpan={8}>
                                                            <strong>Total Payment: {employee4.totalCost}</strong>
                                                        </td>
                                                        <td className="left-aligned" colSpan={2}>
                                                            <strong> Paid Payment: {employee4.amountPayed}</strong>
                                                            <button className="btn ripple btn-primary btn-xs" style={{ marginLeft: "180px", fontSize: "12px" }} onClick={handleOpenModal3}>
                                                                Add Payment
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div
                                className={`modal ${isModalOpen4 ? 'show' : ''}`}
                                style={{ display: isModalOpen4 ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999, position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'auto' }}
                                tabIndex="-1"
                                role="dialog"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-sl" role="document" style={{ maxWidth: '300px', margin: 'auto' }}>
                                    <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }}>
                                        <div className="modal-header" style={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6', borderRadius: '10px 10px 0 0' }}>
                                            <h5 className="modal-title">Permissions</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                aria-label="Close"
                                                onClick={handleCloseModal4}
                                                style={{ outline: 'none', cursor: 'pointer' }}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body d-flex justify-content-center align-items-center" style={{ padding: '20px', textAlign: 'center' }}>
                                            <form>
                                                <div className="modal-body">
                                                    Please confirm if you want to {holdStatus[holdActionId] ? 'unhold' : 'hold'} now.
                                                </div>
                                                <div className="modal-footer d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={handleCloseModal4}
                                                        style={{ marginRight: '10px' }}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={handleOkButtonClick}
                                                    >
                                                        Ok
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '20px', width: '100%', backgroundColor: 'var(--primary-bg-color)', padding: '5px 20px' }}>
                                <h5 style={{ textAlign: 'left', color: '#ffffff', fontSize: '-10rem', margin: '0' }}>Scheme payment</h5>
                            </div>


                            <div className="row row-sm">
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card main-content-body-profile">
                                        <div
                                            className="scroll-container"
                                            style={{
                                                overflowX: "auto",
                                                maxWidth: "100%",
                                                display: "block",
                                                scrollbarWidth: "thick",
                                            }}
                                        >
                                            <style>
                                                {`
                    /* For WebKit browsers */
                    .scroll-container::-webkit-scrollbar {
                        height: 12px;
                    }
                    .scroll-container::-webkit-scrollbar-thumb {
                        background-color: #888;
                        border-radius: 10px;
                        border: 3px solid #ccc;
                    }
                    .scroll-container::-webkit-scrollbar-thumb:hover {
                        background-color: #555;
                    }
                    @media (max-width: 800px) {
                        .scroll-container {
                            overflow-x: auto;
                        }
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        border: 1px solid #fcfcfc;
                    }
                    th, td {
                        border: 1px solid #ccc;
                        padding: 8px 10px;
                        text-align: center;
                        white-space: nowrap;
                    }
                    th {
                        background-color: #f2f2f2;
                        font-weight: bold;
                    }
                    .fixed-width {
                        width: 150px;
                    }
                    .fixed-width-large {
                        width: 200px;
                    }
                    tbody tr.no-data td {
                        text-align: center;
                        padding: 20px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #999;
                    }
                    .left-aligned {
                        text-align: left;
                        padding-left: 10px;
                    }
                    `}
                                            </style>
                                            <table align="center" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th className="fixed-width">Amount Entry</th>
                                                        <th className="fixed-width">Payment Mode</th>
                                                        <th className="fixed-width">Method</th>
                                                        <th className="fixed-width">Bank Name</th>
                                                        <th className="fixed-width-large">AMRS Account</th>
                                                        <th className="fixed-width-large">CHQ/TRN No</th>
                                                        <th className="fixed-width">Cheque Date</th>
                                                        <th className="fixed-width">Payment Date</th>
                                                        <th className="fixed-width-large">Actions</th>
                                                        <th className="fixed-width">Actions Performed By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users3.length > 0 ? (
                                                        users3.map((user) => (
                                                            <tr key={user.id}>
                                                                <td className="fixed-width">{user.amount}</td>
                                                                <td className="fixed-width">{user.collectionMode}</td>
                                                                <td className="fixed-width">
                                                                    {user.collectionMode === 'Online' && user.select}
                                                                    {user.collectionMode === 'Cash Deposit' && 'Cash'}
                                                                    {user.collectionMode === 'Cheque' && 'Cheque'}
                                                                </td>
                                                                <td className="fixed-width">{user.bankName}</td>
                                                                <td className="fixed-width-large">{user.accountNumber}</td>
                                                                <td className="fixed-width-large">
                                                                    {user.transactionNo}
                                                                    {user.chequeNo}
                                                                </td>
                                                                <td className="fixed-width">{user.formattedDate}</td>
                                                                <td className="fixed-width">{user.formattedDate2}</td>
                                                                <td className="fixed-width-large">
                                                                    <Link to={`/view-receipt/${user.id}`} target='_blank' className="btn ripple btn-primary btn-xs" style={{ marginRight: "8px" }}>
                                                                        View Receipt
                                                                    </Link>
                                                                    <button
                                                                        type="button"
                                                                        id={`unholdButton_${user.id}`}
                                                                        className={`btn ripple btn-${holdStatus[user.id] ? 'success' : 'primary'} btn-xs`}
                                                                        style={{ marginRight: "8px" }}
                                                                        onClick={() => { handleOpenModal4(user.id); }}
                                                                    >
                                                                        {holdStatus[user.id] ? 'Hold' : 'Unhold'}
                                                                    </button>
                                                                    <button className="btn ripple btn-primary btn-xs" style={{ marginRight: "8px" }}>
                                                                        Delete
                                                                    </button>
                                                                    <a>
                                                                        <i className="fa fa-edit" style={{ cursor: 'pointer' }} />
                                                                    </a>
                                                                </td>
                                                                <td className="fixed-width-large">
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <div style={{ width: "20px", height: "30px", borderRadius: "50%", overflow: "hidden", marginRight: "5px" }}>
                                                                            <img src={employee.profilePhoto || 'https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-512.png'} alt="Profile" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                                                        </div>
                                                                        <div>
                                                                            {employee.fullName}
                                                                            <br />
                                                                            {user.formattedDates}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr className="no-data">
                                                            <td className="fixed-width" colSpan="10">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '15px', width: '100%', backgroundColor: 'var(--primary-bg-color)', padding: '5px 20px' }}>
                                <h5 style={{ textAlign: 'left', color: '#ffffff', fontSize: '-10rem', margin: '0' }}>Upcoming Payment</h5>

                            </div>

                            <div className="row row-sm" style={{ width: '101.5%' }}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card main-content-body-profile"
                                        style={{
                                            width: "100%",  // Full width for the card
                                            margin: "0 auto",  // Center it on the screen
                                            padding: "20px",
                                        }}>
                                        <div
                                            className="scroll-container"
                                            style={{
                                                overflowX: "auto", // Enable horizontal scroll if necessary
                                                maxWidth: "100%",
                                            }}
                                        >
                                            <style>
                                                {`
                    /* For WebKit browsers */
                    .scroll-container::-webkit-scrollbar {
                        height: 12px;
                    }
                    .scroll-container::-webkit-scrollbar-thumb {
                        background-color: #888;
                        border-radius: 10px;
                        border: 3px solid #ccc;
                    }
                    .scroll-container::-webkit-scrollbar-thumb:hover {
                        background-color: #555;
                    }
                    @media (max-width: 800px) {
                        .scroll-container {
                            overflow-x: auto;
                        }
                    }
                `}
                                            </style>
                                            <table
                                                align="center"
                                                width="100%"
                                                style={{
                                                    borderCollapse: "collapse",
                                                    border: "1px solid #fcfcfc",
                                                }}
                                            >
                                                <thead>
                                                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                        <th style={{ border: "1px solid #ccc", width: "150px" }}>Action</th>
                                                        <th style={{ border: "1px solid #ccc", width: "150px" }}>From</th>
                                                        <th style={{ border: "1px solid #ccc", width: "150px" }}>Date</th>
                                                        <th style={{ border: "1px solid #ccc", width: "150px" }}>Time</th>
                                                        <th style={{ border: "1px solid #ccc", width: "200px" }}>Venue</th>
                                                        <th style={{ border: "1px solid #ccc", width: "200px" }}>Status</th>
                                                        <th style={{ border: "1px solid #ccc", width: "150px" }}>Amount</th>
                                                        <th style={{ border: "1px solid #ccc", width: "250px" }}>Payment Mode</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users2.length > 0 ? (
                                                        users2.map((user, index) => (
                                                            <tr key={index}>
                                                                <td style={{ border: "1px solid #ccc", width: "150px" }}>{user.action}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "150px" }}>{user.from}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "150px" }}>{user.date}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "150px" }}>{user.time}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "200px" }}>{user.venue}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "200px" }}>{user.status}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "150px" }}>{user.amount}</td>
                                                                <td style={{ border: "1px solid #ccc", width: "250px" }}>
                                                                    Mode: {user.mode}
                                                                    <br />
                                                                    {user.mode === 'Cash' && <span> Remark: {user.remark}</span>}
                                                                    {user.mode === 'Online' && <span> Transaction id: {user.transaction}</span>}
                                                                    {user.mode === 'Cheque' && (
                                                                        <>
                                                                            <span> Cheque No: {user.chequeNo}</span>
                                                                            <br />
                                                                            <span> Cheque Date: {user.chequeDate}</span>
                                                                            <br />
                                                                            <span> Cheque Details Bank: {user.chequeDetails}</span>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="8" style={{ textAlign: "center" }}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div style={{ marginTop: '25px', width: '100%', backgroundColor: 'var(--primary-bg-color)', padding: '5px 20px' }}>
                                <h5 style={{ textAlign: 'left', color: '#ffffff', fontSize: '-10rem', margin: '0' }}>Payment Schedule</h5>
                            </div>



                            <div className="row row-sm mt-0 justify-content-around" style={{ width: '400%', marginTop: '0x' }}>
                                <div className="col-xl-12 col-xl-12 ">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive" >
                                                {employee4.paymentPlan === 'FPP' && (
                                                    <>
                                                        <h4
                                                            className="mt-0 text-center mb-3"
                                                            style={{ color: "#2e3192" }}
                                                        >
                                                            FLEXI PAYMENT PLAN
                                                        </h4>
                                                        <table className="table table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <th className="tx-left" />
                                                                    <th className="tx-left" style={{ width: 350 }}>
                                                                        Basic Price ₹{" "}
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ width: 90, height: 25, display: "inline" }}

                                                                            value={employee4 && employee4.plan && employee4.plan.basicPriceFPP ? `${employee4.plan.basicPriceFPP}` : ''}
                                                                            disabled={disableInput2}

                                                                        />
                                                                        {" "} per {" "}
                                                                        {employee4 && employee4.plan && employee4.plan.areaUnitFPP ? `${employee4.plan.areaUnitFPP}` : ''}

                                                                    </th>
                                                                    <th className="tx-left" />
                                                                    <th className="tx-left">
                                                                        <span style={{ marginRight: 10, fontSize: "11px" }}>
                                                                            Installment Amount for {employee4.size}
                                                                            <span style={{ fontSize: '9px' }}>
                                                                                {employee4 && employee4.plan && employee4.plan.areaUnitFPP ? `${employee4.plan.areaUnitFPP}` : ''}
                                                                            </span>
                                                                        </span>

                                                                        <button
                                                                            onClick={handleOpenModal16}
                                                                            title="View"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                backgroundColor: 'var(--primary-bg-color)',
                                                                                color: '#fff',
                                                                                border: 'none',
                                                                                padding: '5px 10px',
                                                                                borderRadius: '5px',
                                                                                fontSize: '12px',
                                                                                marginLeft: '10px',
                                                                            }}
                                                                        >
                                                                            View Payment Breakup
                                                                        </button>
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">1</td>
                                                                    <td className="tx-left">On Booking</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}

                                                                            value={employee4 && employee4.plan && employee4.plan.onBookingPerFPP ? `${employee4.plan.onBookingPerFPP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}

                                                                            value={total1}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">2</td>
                                                                    <td className="tx-left">
                                                                        Within 24 Months. Monthly installment Each of:-
                                                                    </td>
                                                                    <td className="tx-left" />
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}

                                                                            value={ins}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left" />
                                                                    <td className="tx-left">Total value</td>
                                                                    <td className="tx-left">

                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}

                                                                            value={employee4.totalCost}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}


                                                {employee4.paymentPlan === 'DLP' && (
                                                    <>
                                                        <h4
                                                            className="mt-0 text-center mb-3"
                                                            style={{ color: "#2e3192" }}
                                                        >
                                                            DOWN LINK PLAN
                                                        </h4>
                                                        <table className="table table-bordered" style={{ width: 1280 }}>
                                                            <thead>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <th className="tx-left" />

                                                                    <th className="tx-left" style={{ width: 350 }}>
                                                                        Basic Price ₹{" "}
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ width: 90, height: 25, display: "inline" }}
                                                                            value={employee4 && employee4.plan && employee4.plan.basicPriceDLP ? `${employee4.plan.basicPriceDLP}` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                        {" "} per {" "}
                                                                        {employee4 && employee4.plan && employee4.plan.areaUnitDLP ? `${employee4.plan.areaUnitDLP}` : ''}

                                                                    </th>
                                                                    <th className="tx-left" />
                                                                    <th className="tx-left">
                                                                        <span style={{ marginRight: 10, fontSize: "11px" }}>
                                                                            Installment Amount for {employee4.size}{" "}
                                                                            <span style={{ fontSize: '9px' }}>
                                                                                {employee4 && employee4.plan && employee4.plan.areaUnitDLP ? `${employee4.plan.areaUnitDLP}` : ''}
                                                                            </span>
                                                                        </span>

                                                                        <button
                                                                            onClick={handleOpenModal16}
                                                                            title="View"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                backgroundColor: 'var(--primary-bg-color)',
                                                                                color: '#fff',
                                                                                border: 'none',
                                                                                padding: '5px 10px',
                                                                                borderRadius: '5px',
                                                                                fontSize: '12px',
                                                                                marginLeft: '10px',
                                                                            }}
                                                                        >
                                                                            View Payment Breakup
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">1</td>
                                                                    <td className="tx-left">On Booking</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="onBookingPerDLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.onBookingPerDLP ? `${employee4.plan.onBookingPerDLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="onBookingDLP"
                                                                            value={total2}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">2</td>
                                                                    <td className="tx-left">Within {employee4 && employee4.plan && employee4.plan.daysDLP ? `${employee4.plan.daysDLP}` : ''} Days-</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn30PerDLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.withIn30PerDLP ? `${employee4.plan.withIn30PerDLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn30DLP"
                                                                            value={total3}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">3</td>
                                                                    <td className="tx-left">Rest on Registry</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="restOnRegistryPerDLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.restOnRegistryPerDLP ? `${employee4.plan.restOnRegistryPerDLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="restOnRegistryDLP"
                                                                            value={total3}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left" />
                                                                    <td className="tx-left">Total Value</td>
                                                                    <td className="tx-left">

                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="totalValueDLP"
                                                                            value={employee4.totalCost}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}


                                                {employee4.paymentPlan === 'PLP' && (
                                                    <>
                                                        <h4
                                                            className="mt-0 text-center mb-3"
                                                            style={{ color: "#2e3192" }}
                                                        >
                                                            POSSESSION LINK PLAN
                                                        </h4>
                                                        <table className="table table-bordered" style={{ width: 1280 }}>
                                                            <thead>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <th className="tx-left" />
                                                                    <th className="tx-left" style={{ width: 350 }}>
                                                                        Basic Price ₹{" "}
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            style={{ width: 90, height: 25, display: "inline" }}
                                                                            name="basicPricePLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.basicPricePLP ? `${employee4.plan.basicPricePLP}` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                        {" "} per {" "}
                                                                        {employee4 && employee4.plan && employee4.plan.areaUnitPLP ? `${employee4.plan.areaUnitPLP}` : ''}
                                                                    </th>
                                                                    <th className="tx-left" />
                                                                    <th className="tx-left">
                                                                        <span style={{ marginRight: 10, fontSize: "11px" }}>
                                                                            Installment Amount for {employee4.size}{" "}
                                                                            <span style={{ fontSize: '9px' }}>
                                                                                {employee4 && employee4.plan && employee4.plan.areaUnitPLP ? `${employee4.plan.areaUnitPLP}` : ''}
                                                                            </span>
                                                                        </span>

                                                                        <button
                                                                            onClick={handleOpenModal16}
                                                                            title="View"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                backgroundColor: 'var(--primary-bg-color)',
                                                                                color: '#fff',
                                                                                border: 'none',
                                                                                padding: '5px 10px',
                                                                                borderRadius: '5px',
                                                                                fontSize: '12px',
                                                                                marginLeft: '10px',
                                                                            }}
                                                                        >
                                                                            View Payment Breakup
                                                                        </button>
                                                                    </th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">1</td>
                                                                    <td className="tx-left">On Booking</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="onBookingPerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.onBookingPerPLP ? `${employee4.plan.onBookingPerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="onBookingPLP"
                                                                            value={total5}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">2</td>
                                                                    <td className="tx-left">Within  {employee4 && employee4.plan && employee4.plan.days1PLP ? `${employee4.plan.days1PLP}` : ''} Days-</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn60PerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.withIn60PerPLP ? `${employee4.plan.withIn60PerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        // style={{ color: 'white', backgroundColor: 'gray' }}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn60PLP"
                                                                            value={total6}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">3</td>
                                                                    <td className="tx-left">Within  {employee4 && employee4.plan && employee4.plan.days2PLP ? `${employee4.plan.days2PLP}` : ''} Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn90PerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.withIn90PerPLP ? `${employee4.plan.withIn90PerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn90PLP"
                                                                            value={total7}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">4</td>
                                                                    <td className="tx-left">Within  {employee4 && employee4.plan && employee4.plan.days3PLP ? `${employee4.plan.days3PLP}` : ''} Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn120PerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.withIn120PerPLP ? `${employee4.plan.withIn120PerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn120PLP"
                                                                            value={total8}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">5</td>
                                                                    <td className="tx-left">Within {" "} {employee4 && employee4.plan && employee4.plan.days4PLP ? `${employee4.plan.days4PLP}` : ''}{" "} Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn150PerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.withIn150PerPLP ? `${employee4.plan.withIn150PerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn150PLP"
                                                                            value={total9}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">6</td>
                                                                    <td className="tx-left">Within{" "}
                                                                        {employee4 && employee4.plan && employee4.plan.days5PLP ? `${employee4.plan.days5PLP}` : ''}{" "}
                                                                        Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn180PerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.withIn180PerPLP ? `${employee4.plan.withIn180PerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="withIn120PLP"
                                                                            value={total10}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">7</td>
                                                                    <td className="tx-left">Within{" "}
                                                                        {employee4 && employee4.plan && employee4.plan.days6PLP ? `${employee4.plan.days6PLP}` : ''}{" "}
                                                                        Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="extraPerPLP1"
                                                                            value={employee4 && employee4.plan && employee4.plan.extraPerPLP1 ? `${employee4.plan.extraPerPLP1}%` : ''}
                                                                            disabled={disableInput2}

                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="extraValuePLP1"
                                                                            value={total11}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>



                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">8</td>
                                                                    <td className="tx-left">Within{" "}
                                                                        {employee4 && employee4.plan && employee4.plan.days7PLP ? `${employee4.plan.days7PLP}` : ''}{" "}
                                                                        Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="extraPerPLP2"
                                                                            value={employee4 && employee4.plan && employee4.plan.extraPerPLP2 ? `${employee4.plan.extraPerPLP2}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="extraValuePLP2"
                                                                            value={total12}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">9</td>
                                                                    <td className="tx-left">Within {" "}
                                                                        {employee4 && employee4.plan && employee4.plan.days8PLP ? `${employee4.plan.days8PLP}` : ''}{" "}
                                                                        Days</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="extraPerPLP3"
                                                                            value={employee4 && employee4.plan && employee4.plan.extraPerPLP3 ? `${employee4.plan.extraPerPLP3}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="extraValuePLP3"
                                                                            value={total13}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>

                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left">10</td>
                                                                    <td className="tx-left">Rest on Registry</td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="restOnRegistryPerPLP"
                                                                            value={employee4 && employee4.plan && employee4.plan.restOnRegistryPerPLP ? `${employee4.plan.restOnRegistryPerPLP}%` : ''}
                                                                            disabled={disableInput2}
                                                                        />
                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="restOnRegistryPLP"
                                                                            value={total14}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ background: "#d6f6ff26" }}>
                                                                    <td className="tx-left" />

                                                                    <td className="tx-left">
                                                                        Total Value
                                                                    </td>
                                                                    <td className="tx-left">

                                                                    </td>
                                                                    <td className="tx-left">
                                                                        <input type="text" className="form-control"
                                                                            style={{ height: 25 }}
                                                                            name="totalValuePLP"
                                                                            value={employee4.totalCost}
                                                                            disabled={disableInput2} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {employee4.paymentPlan === 'FPP' && (
                                <div className="row row-xl mt-4 justify-content-around" style={{ width: '400%' }}>
                                    <div className="col-xl-12 col-lg-12 col-md-12" >
                                        <div className="card custom-card main-content-body-profile">
                                            <div className="card-body" style={{
                                                overflowX: "auto",
                                                maxWidth: "100%",
                                                display: "block",
                                            }}>
                                                <div className="table-responsive" style={{ width: '100%' }}>

                                                    {employee4.paymentPlan === 'FPP' && (
                                                        <>
                                                            <h4
                                                                className="mt-0 text-center mb-3"
                                                                style={{ color: "#2e3192" }}
                                                            >
                                                                {employee4.paymentPlan} EMI DETAILS 24 MONTHS
                                                            </h4>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr style={{ background: "#d6f6ff26" }}>
                                                                        <th className="tx-left" >Emi no</th>

                                                                        <th className="tx-left" >Open Balance</th>
                                                                        <th className="tx-left"> Due Payment</th>
                                                                        <th className="tx-left"> Due Date</th>
                                                                        <th className="tx-left">Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {users4.length > 0 ? (
                                                                        users4.map((user) => (
                                                                            <tr style={{ background: "#d6f6ff26" }}>
                                                                                <td className="tx-left">{user.emiNo}</td>

                                                                                <td className="tx-left">{user.openBalance}</td>
                                                                                <td className="tx-left">{user.dueAmount}{" "}
                                                                                    <span style={{ display: 'inline-block', textAlign: 'right', color: '#4CAF50', fontWeight: 'bold', marginRight: '40px' }}>
                                                                                        {user.paymentInfo}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="tx-left">{user.paymentDate}</td>
                                                                                <td className="tx-left">{user.status}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr className="no-data">
                                                                            <td className="fixed-width" colSpan="10">No data available</td>
                                                                        </tr>
                                                                    )}

                                                                    {/* <tr style={{ background: "#d6f6ff26" }}>
                                                                        <td className="tx-left" colSpan="5">Total value</td>
                                                                        <td className="tx-left"> 10,00000</td>
                                                                    </tr> */}

                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )}


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}

                            {employee4.paymentPlan === 'PLP' && (
                                <div className="row row-xl mt-4 justify-content-around" style={{ width: '400%' }}>
                                    <div className="col-xl-12 col-lg-12 col-md-12" >
                                        <div className="card custom-card main-content-body-profile">
                                            <div className="card-body" style={{
                                                overflowX: "auto",
                                                maxWidth: "100%",
                                                display: "block",
                                            }}>
                                                <div className="table-responsive" style={{ width: '100%' }}>

                                                    {employee4.paymentPlan === 'PLP' && (
                                                        <>
                                                            <h4
                                                                className="mt-0 text-center mb-3"
                                                                style={{ color: "#2e3192" }}
                                                            >
                                                                {employee4.paymentPlan} PAYMENT DATES SCHEDULES
                                                            </h4>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr style={{ background: "#d6f6ff26" }}>
                                                                        <th className="tx-left" >Payment No</th>

                                                                        <th className="tx-left" >Open Balance</th>
                                                                        <th className="tx-left">Installment Amount</th>
                                                                        <th className="tx-left">Due Date</th>
                                                                        <th className="tx-left">Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {users4.length > 0 ? (
                                                                        users4.map((user) => (
                                                                            <tr style={{ background: "#d6f6ff26" }}>
                                                                                <td className="tx-left">{user.id}</td>

                                                                                <td className="tx-left">{user.openBalance}</td>
                                                                                <td className="tx-left">{user.dueAmount} {" "}
                                                                                    <span style={{ display: 'inline-block', textAlign: 'right', color: '#4CAF50', fontWeight: 'bold', marginRight: '40px' }}>
                                                                                        {user.paymentInfo}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="tx-left">{user.paymentDate}</td>
                                                                                <td className="tx-left">{user.status}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr className="no-data">
                                                                            <td className="fixed-width" colSpan="10">No data available</td>
                                                                        </tr>
                                                                    )}

                                                                    {/* <tr style={{ background: "#d6f6ff26" }}>
                                                                        <td className="tx-left" colSpan="5">Total value</td>
                                                                        <td className="tx-left"> 10,00000</td>
                                                                    </tr> */}

                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )}


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}

                            {employee4.paymentPlan === 'DLP' && (
                                <div className="row row-xl mt-4 justify-content-around" style={{ width: '400%' }}>
                                    <div className="col-xl-12 col-lg-12 col-md-12" >
                                        <div className="card custom-card main-content-body-profile">
                                            <div className="card-body" style={{
                                                overflowX: "auto",
                                                maxWidth: "100%",
                                                display: "block",
                                            }}>
                                                <div className="table-responsive" style={{ width: '100%' }}>

                                                    {employee4.paymentPlan === 'DLP' && (
                                                        <>
                                                            <h4
                                                                className="mt-0 text-center mb-3"
                                                                style={{ color: "#2e3192" }}
                                                            >
                                                                {employee4.paymentPlan} PAYMENT DATES SCHEDULES
                                                            </h4>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr style={{ background: "#d6f6ff26" }}>
                                                                        <th className="tx-left" >Payment No</th>

                                                                        <th className="tx-left" >Open Balance</th>
                                                                        <th className="tx-left">Installment Amount</th>
                                                                        <th className="tx-left">Due Date</th>
                                                                        <th className="tx-left">Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {users4.length > 0 ? (
                                                                        users4.map((user) => (
                                                                            <tr style={{ background: "#d6f6ff26" }}>
                                                                                <td className="tx-left">{user.id}</td>

                                                                                <td className="tx-left">{user.openBalance}</td>
                                                                                <td className="tx-left">{user.dueAmount} {" "}
                                                                                    <span style={{ display: 'inline-block', textAlign: 'right', color: '#4CAF50', fontWeight: 'bold', marginRight: '40px' }}>
                                                                                        {user.paymentInfo}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="tx-left">{user.paymentDate}</td>
                                                                                <td className="tx-left">{user.status}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr className="no-data">
                                                                            <td className="fixed-width" colSpan="10">No data available</td>
                                                                        </tr>
                                                                    )}

                                                                    {/* <tr style={{ background: "#d6f6ff26" }}>
                                                                        <td className="tx-left" colSpan="5">Total value</td>
                                                                        <td className="tx-left"> 10,00000</td>
                                                                    </tr> */}

                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )}


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>

                <div
                    className={`modal fade ${isModalOpen16 ? 'show d-block' : ''}`}
                    id="modaldemo-callback-form"
                    tabIndex="-1"
                    style={{
                        display: isModalOpen16 ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: isModalOpen16 ? 'hidden' : 'auto'
                    }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-xl"
                        style={{ maxWidth: '30%', margin: 'auto' }}
                    >
                        <div
                            className="modal-content"
                            style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)'
                            }}
                        >
                            <div
                                className="modal-header"
                                style={{
                                    backgroundColor: '#f8f9fa',
                                    borderBottom: '1px solid #dee2e6',
                                    borderRadius: '10px 10px 0 0'
                                }}
                            >
                                <h5 className="modal-title">View Payment</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCloseModal16}
                                    aria-label="Close"
                                    style={{ outline: 'none', cursor: 'pointer' }}
                                ></button>
                            </div>

                            <div className="modal-body" style={{ padding: '20px' }}>
                                <form>
                                    <div className="row row-sm">
                                        <div className="col-sm-12 form-group">
                                            <div style={{ marginBottom: '10px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc1"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        Project: {employee4.projectId}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        Unit no: {employee4.unitNo}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        {employee4?.inventory?.type === 'Plot' && (
                                                            <>
                                                                Size: {employee4?.inventory?.size} SQ YD
                                                            </>
                                                        )}
                                                        {employee4?.inventory?.type === 'Farmhouse' && (
                                                            <>
                                                                Size: {employee4?.inventory?.size} SQ YD
                                                            </>
                                                        )}
                                                        {employee4?.inventory?.type === 'Shop' && (
                                                            <>
                                                                Size: {employee4?.inventory?.size} SQ FT
                                                            </>
                                                        )}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        BSP: Rs. {employee4.bsp}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        UNIT Price: Rs. {employee4.unitPrice}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        Fixed Charges(EDS/IDS): Rs. {employee4.fixedCharges}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        EDS/IDS Amount: Rs. {employee4.fixedAmount}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        PLCs: {employee4.PLCs}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        PLCs Value: Rs. {employee4.plcAmount}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        Total cost: Rs. {employee4.totalCost}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        Payment Plan: {employee4.paymentPlan}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                    >
                                                        Gift: {employee4.gift}
                                                    </label>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        className="form-label"
                                                        htmlFor="kyc2"
                                                        style={{ marginLeft: '8px', cursor: 'pointer', color: 'red' }}
                                                    >
                                                        Registration Amount: {employee4.registrationAmount}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal fade ${isModalOpen3 ? 'show d-block' : ''}`}
                    id="modaldemo-callback-form"
                    tabIndex="-1"
                    style={{ display: isModalOpen3 ? 'flex' : 'none', top: '20px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl" style={{ maxWidth: '40%', margin: 'auto' }}>
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }}>
                            <div className="modal-header" style={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6', borderRadius: '10px 10px 0 0' }}>
                                <h5 className="modal-title">Add Payment</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCloseModal3}
                                    aria-label="Close"
                                    style={{ outline: 'none', cursor: 'pointer' }}
                                ></button>
                            </div>

                            <div className="modal-body" style={{ padding: '20px' }}>
                                <form>
                                    <div className="row row-sm">

                                        <div className="col-sm-6 form-group">
                                            <label className="form-label">Amount: <span className="tx-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="amount"
                                                value={formData.amount}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label"> Collection Mode: <span className="tx-danger">*</span></label>
                                            <select className="form-control select2"

                                                name="collectionMode"
                                                value={formData.collectionMode}
                                                onChange={handleInputChange}>
                                                <option value=''>Select </option>
                                                <option value={'Cash Deposit'}>Cash</option>
                                                <option value={'Online'}>Online</option>
                                                <option value={'Cheque'}>Cheque</option>

                                            </select>
                                        </div>
                                        {formData.collectionMode === 'Cheque' && (
                                            <>
                                                <div className="col-sm-6 form-group" style={{ marginTop: '10px' }}>
                                                    <label className="form-label">Cheque Date</label>
                                                    <input type="date" className="form-control"
                                                        name="chequeDate"
                                                        value={formData.chequeDate}
                                                        onChange={handleInputChange} />
                                                </div>
                                                <div className="col-sm-6 form-group" style={{ marginTop: '10px' }}>
                                                    <label className="form-label">Cheque No</label>
                                                    <input type="text" className="form-control"
                                                        name="chequeNo"
                                                        value={formData.chequeNo}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-sm-6 form-group" style={{ marginTop: '10px' }}>
                                                    <label className="form-label">Deposit to AMRS</label>
                                                    <select className="form-control select2"
                                                        name="amrsAccount"
                                                        value={formData.amrsAccount}
                                                        onChange={handleInputChange}

                                                    >
                                                        <option value=''>Select </option>
                                                        {project.map((option, index) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className="col-sm-6 form-group" >
                                                    <label className="form-label">Bank Cheque</label>
                                                    <input type="text" className="form-control"
                                                        name="deposteToAmrs"
                                                        value={formData.deposteToAmrs}
                                                        onChange={handleInputChange} />
                                                </div>

                                            </>
                                        )}
                                        {formData.collectionMode === 'Cash Deposit' && (
                                            <>

                                                <div className="col-sm-6 form-group" style={{ marginTop: '10px' }}>
                                                    <label className="form-label">Enter Discount</label>
                                                    <input type="text" className="form-control"
                                                        name="enterDiscount"
                                                        value={formData.enterDiscount}
                                                        onChange={handleInputChange} />
                                                </div>

                                            </>
                                        )}
                                        {formData.collectionMode === 'Online' && (
                                            <>
                                                <div className="col-sm-6 form-group" style={{ marginTop: '10px' }}>
                                                    <label className="form-label">Select</label>
                                                    <select className="form-control select2"
                                                        name="select"
                                                        value={formData.select}
                                                        onChange={handleInputChange}

                                                    >
                                                        <option value=''>Select </option>
                                                        <option >IMPS</option>
                                                        <option >NEFT</option>
                                                        <option >RTGS</option>

                                                    </select>
                                                </div>
                                                <div className="col-sm-6 form-group" style={{ marginTop: '10px' }}>
                                                    <label className="form-label">AMRS Account</label>
                                                    <select className="form-control select2"
                                                        name="amrsAccount"
                                                        value={formData.amrsAccount}
                                                        onChange={handleInputChange}

                                                    >
                                                        <option value=''>Select </option>
                                                        {project.map((option, index) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div className="col-sm-6 form-group" >
                                                    <label className="form-label">Transaction No</label>
                                                    <input type="text" className="form-control"
                                                        name="transactionNo"
                                                        value={formData.transactionNo}
                                                        onChange={handleInputChange} />
                                                </div>




                                            </>
                                        )}

                                        <div className="col-sm-6 form-group">
                                            <label className="form-label">Payment Credit Date </label>
                                            <input type="date" className="form-control"
                                                name="paymentCredit"
                                                value={formData.paymentCredit}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-sm-6 form-group">
                                            <label className="form-label">Handover By</label>
                                            <input type="text" className="form-control"
                                                name="handOverBy"
                                                value={formData.handOverBy}
                                                onChange={handleInputChange} />
                                        </div>


                                        <div className="col-sm-6">
                                            <label className="form-label"> Collect By <span className="tx-danger">*</span></label>
                                            <select
                                                className="form-control select2"
                                                name="collectedBy"
                                                value={formData.collectedBy}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {reportingBossA.map((option, index) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.fullName}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label">Gift<span className="tx-danger">*</span></label>
                                            <select
                                                className="form-control select2"
                                                name="gift"
                                                value={formData.gift}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {gift.map((option, index) => (
                                                    <option key={option.name} value={option.name}>
                                                        {option.giftName}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label"> Upload Receipt</label>
                                            <input type="file" className="form-control"
                                                name="uploadRecipt"
                                                accept="/pdf"
                                                onChange={handleFileChange15} />
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer" style={{ borderTop: '1px solid #dee2e6', borderRadius: '0 0 10px 10px', backgroundColor: '#f8f9fa' }}>
                                <button className="btn ripple btn-primary" type="button" style={{
                                    borderRadius: '5px',
                                    padding: '8px 20px', fontSize: '14px', fontWeight: 'bold'
                                }} onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-footer text-center">
                    <div className="">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default SingalPaymentLadger



