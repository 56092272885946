
import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const DemandLatterView = () => {
    const { empid } = useParams();
    const navigate = useNavigate();
    const initialFormData = {
        subject: '',
        name: '',
        projectName: '',
        clientName: '',
        ticketId: '',
        allotmentUnit: '',
        area: '',
        paymnetPlan: '',
        basicSalesPrice: '',
        fixedCharges: '',
        plc: '',
        totalCost: '',
        amountPaid: '',
        paymentDue: '',
        duePaymentDate: '',
        bank: '',
        noteDate: '',
        interest: '',
        basicSalesPriceAmount: '',
        fixedChargesAmount: '',
        plcAmount: '',
        date: '',

    };
    const [formData, setFormData] = useState(initialFormData);
    const [employee, setEmployee] = useState({})
  
    const apiUrl = process.env.REACT_APP_URL;
    const Token = localStorage.getItem("Token");


    useEffect(() => {
        async function getEmp() {
            const Token = localStorage.getItem("Token");

            let response = await fetch(`${apiUrl}/bank/getBankDetailsById/${formData.bank}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${Token}`
                },
            });
            response = await response.json();

            console.log("API response:", response);

            if (response.status === "success") {
                setEmployee(response.data);
            }
        }
        getEmp();
    }, [formData.bank]);

    useEffect(() => {
        const fetchUser = async () => {
          const Token = localStorage.getItem('Token');
          try {
            const url = `${apiUrl}/letter/getDemandLetter/${empid}`;
    
            const result = await fetch(url, {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Content-Type': 'application/json',
              },
            });
    
            const { data } = await result.json();

            setFormData((prevFormData) => ({
              ...prevFormData,
              ...data,
            }));
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchUser();
      }, [apiUrl, empid]);



    return (
        <div>
            <div style={{ background: "#fff", margin: 0, padding: "50px 0" }}>
                <table
                    align="center"
                    width={900}
                    border={0}
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                        background: "#f7f7f7",
                        fontFamily: '"Poppins", sans-serif',
                        fontSize: 13,
                        borderRadius: 15
                    }}
                >
                    <tbody>
                        <tr>
                            <td colSpan={3} style={{ height: 20 }} />
                        </tr>
                        <td align="center" colSpan={3}><strong>{formData.date}</strong></td>
                        <tr>
                            <td align="center" colSpan={3}>
                                <a href="">
                                    <img border={0} width={150} src="https://amrealty.webkype.com/assets/img/brand/logo.png" alt='' />
                                </a>
                                <br />
                                <h3>Demand Letter</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: 30 }} />
                            <td>
                                <table
                                    align="center"
                                    width="100%"
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>
                                                    Subject:{" "}
                                                    <span style={{ color: 'blue' }}>{formData.subject}</span>
                                                </p>
                                                <p>
                                                    Dear Mr/Ms/Mrs:{" "}
                                                    <span style={{ color: 'blue' }}>{formData.name}</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 30 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Greetings from AM Realty Solutions!!!!!</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>
                                                    This is to notify you that the following payment is due as
                                                    per the allotment of Plot/Farm House under {" "}<span style={{ color: 'blue' }}>{formData.schemeName}</span>  {" "}
                                                    <b>
                                                        (भारतीय स्मार्ट शहर व
                                                        पर्यटनविकास योजना (आई.एस.सी.टी.डी.पी.) फुलेरा / सांभर /
                                                        शाहपुरा )
                                                    </b>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                <table
                                                    align="center"
                                                    width="100%"
                                                    style={{ borderCollapse: 'collapse', borderRadius: '5px', backgroundColor: '#fff' }}
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: '10px' }}>Project Name: <span style={{ color: 'blue' }}>{formData.projectName}</span></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 30 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                                                    Brief details about the total cost of the unit and payment
                                                    plan are as follows:
                                                </h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table
                                                    align="center"
                                                    width="100%"
                                                    style={{
                                                        borderCollapse: "collapse",
                                                        border: "1px solid #fcfcfc",
                                                    }}
                                                >
                                                    <thead>
                                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>Client Name</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>Allotted Unit</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>Area (SQ YD)</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>Payment Plan</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>Basic Sales Price (Per Sq. Ft)</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>EDC/IDC (Per Sq. Ft)</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>PLC (in %)</th>
                                                            <th style={{ padding: "5px 10px", border: "1px solid #ccc", textAlign: "center" }}>Total Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.clientName}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.allotmentUnit}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.size}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.paymnetPlan}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.basicSalesPrice}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span>{formData.fixedCharges}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.plc}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}> </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }} colSpan={4}></td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.basicSalesPriceAmount}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.fixedChargesAmount}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.plcAmount}</span>
                                                            </td>
                                                            <td style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                                                                <span style={{ color: 'blue' }}>{formData.totalCost}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 30 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                                                    PAYMENT DEMAND
                                                </h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table
                                                    align="center"
                                                    width="100%"
                                                    border={1}
                                                    cellSpacing={0}
                                                    cellPadding={0}
                                                    style={{ borderColor: "#fcfcfc" }}
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: "5px 10px", width: "60%" }}>
                                                                Amount Paid: <span style={{ color: 'blue' }}>{formData.amountPaid}</span>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "5px 10px" }}>
                                                                Payment Due (Demand) as per payment Plan: <span style={{ color: 'blue' }}>{formData.paymentDue}</span>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 20 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                                    In accordance with the terms of the allotment, due payment
                                                    need to be paid before{" "}
                                                    <span style={{ color: 'blue' }}>{formData.duePaymentDate}</span>
                                                </p>
                                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                                    {" "}
                                                    You are requested to make the due payment by Demand Draft/
                                                    Cheque drawn in favour of “<span style={{ color: 'blue' }}>{formData.projectId}</span>” at the
                                                    earliest.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 20 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                                                    You can also transfer payment online using Bank details as
                                                    follows:
                                                </h4>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ height: 20 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                                    {" "}
                                                    Note: With effect from{" "}
                                                    <span style={{ color: 'blue' }}>{formData.noteDate}</span>{" "}
                                                    , an interest of @{" "}
                                                    <span style={{ color: 'blue' }}>{formData.interest}</span>{" "}
                                                    % p.a compounded quarterly shall be payable on all delayed
                                                    payments.
                                                </p>
                                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                                    All Payments will be updated on Mobile APP/WEB.
                                                </p>
                                                <p style={{ marginTop: 0, marginBottom: 10 }}>
                                                    Thanking you &amp; assuring you of our best services always.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: 20 }} />
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>
                                                    <b>Bank Details</b>
                                                    <br />
                                                    Title: <span style={{ color: 'blue' }}>{employee.title}</span>
                                                    <br />
                                                    Account Name: <span style={{ color: 'blue' }}>{employee.accountName}</span>
                                                    <br />
                                                    Account Number: <span style={{ color: 'blue' }}>{employee.accountNumber}</span>
                                                    <br />
                                                    Bank Name: <span style={{ color: 'blue' }}>{employee.bankName}</span>
                                                    <br />
                                                    Branch: <span style={{ color: 'blue' }}>{employee.branch}</span>
                                                    <br />
                                                    IFSC: <span style={{ color: 'blue' }}>{employee.ifsc}</span>

                                                </p>
                                                <br />
                                                <p>
                                                    <b>With Best Regards</b>
                                                    <br />
                                                    Apoorva Srivastava
                                                    <br />
                                                    Accounts Manager
                                                    <br />
                                                </p>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{ width: 30 }} />
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ height: 20 }} />
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default DemandLatterView
