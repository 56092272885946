import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AllotmentLetter = () => {
  const { empid } = useParams();
  const navigate = useNavigate();
  const initialFormData = {

    applicantFirstName: '',
    applicantMiddleName: '',
    applicantLastName: '',
    applicantFatherName: '',
    applicantEmail: '',
    applicantMobile: '',
    applicantAlternateNumber: '',
    applicantCountry: '',
    applicantState: '',
    applicantCity: "",
    applicantAddress: '',
    applicantDOB: '',
    applicantAadhaarNumber: "",
    applicantAadhaarImage: "",
    applicantPAN: "",
    applicantPanImage: "",
    applicantNationality: "",
    applicantProfession: "",
    coApplicantFirstName: "",
    coApplicantMiddleName: "",
    coApplicantLastName: "",
    coApplicantFatherName: " ",
    coApplicantEmail: "",
    coApplicantMobile: "",
    coApplicantAlternateNumber: "",
    coApplicantCountry: "",
    coApplicantState: "",
    coApplicantCity: "",
    coApplicantAddress: "",
    coApplicantDOB: "",
    coApplicantAadhaarNumber: "",
    coApplicantAadhaarImage: "",
    coApplicantPAN: "",
    coApplicantPanImage: "",
    coApplicantNationality: "",
    coApplicantProfession: "",
    projectId: '',
    schemeId: '',
    planId: '',
    drawSchemeAmount: '',
    advisorId: '',
    sizeType: "",
    others: "",
    amountReceived: "",
    paymentStatus: "",
    paymentMethod: "",
    transactionID: "",
    applicantImage: "",
    coApplicantImage: "",

    applicantPincode: '',
    applicantLandMark: '',
    coApplicantPincode: '',
    coApplicantLandMark: '',

    shipPlotFarmNo: '',
    registrationAmount: '',
    area: '',

    bsp: '',
    fixedCharges: '',
    PLCs: '',
    totalCost: '',

    cashRemark: '',
    chequeNo: '',
    chequeDetails: '',
    chequeDate: '',

    schemeType: '',
    unitNo: '',
    gift: '',
    paymentPlan: '',
    PLCsValue: '',

  };

  const initialFormData3 = {

    applicantName: '',
    applicantSdwOf: '',
    applicantPhoto: '',
    applicantPhoneNumber: '',
    applicantDob: '',
    applicantNationality: '',
    applicantPanNo: '',
    applicantProfession: '',
    applicantEmailId: '',
    applicantAadharNo: '',
    applicantAddress: '',
    coApplicantName: '',
    coApplicantSdwOf: '',
    coApplicantPhoto: '',
    coApplicantPhone: '',
    coApplicantDob: '',
    coApplicantNationality: '',
    coApplicantProfession: '',
    coApplicantEmailId: '',
    coApplicantAadharNumber: '',
    coApplicantAddress: '',
    developmentCharges: '',
    area: '',
    unitNo: '',
    plc: '',
    paymentPlan: '',
    plcAmount: '',
    registrationAmount: '',
    totalCost: '',
    modeOfPayment: '',
    chequeDDno: '',
    bankName: '',
    chequeDDAmount: '',
    chequeDDdate: '',
    transactionId: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [formData3, setFormData3] = useState(initialFormData3);
  const [profilePic, setProfilePic] = useState(null);
  const [profilePic2, setProfilePic2] = useState(null);

  const formatDateee = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
  };

  const [currentDate, setCurrentDate] = useState(formatDateee(new Date()));
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");

  const buttonStyle = {
    backgroundColor: '#4CAF50',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '14px',
    margin: '4px',
    cursor: 'pointer',
    borderRadius: '8px',
    position: 'relative',
    left: '10px',
    transition: 'background-color 0.3s ease, transform 0.3s ease'
  };


  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantName: `${formData.applicantFirstName} ${formData.applicantMiddleName} ${formData.applicantLastName}` }));
  }, [formData.applicantFirstName, formData.applicantMiddleName, formData.applicantLastName]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantSdwOf: formData.applicantFatherName }));
  }, [formData.applicantFatherName]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantPhoneNumber: formData.applicantMobile }));
  }, [formData.applicantMobile]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantDob: formData.applicantDOB }));
  }, [formData.applicantDOB]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantNationality: formData.applicantNationality }));
  }, [formData.applicantNationality]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantPanNo: formData.applicantPAN }));
  }, [formData.applicantPAN]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantProfession: formData.applicantProfession }));
  }, [formData.applicantProfession]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantEmailId: formData.applicantEmail }));
  }, [formData.applicantEmail]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantAadharNo: formData.applicantAadhaarNumber }));
  }, [formData.applicantAadhaarNumber]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantAddress: formData.applicantAddress }));
  }, [formData.applicantAddress]);

  //co applicant 


  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantName: `${formData.coApplicantFirstName} ${formData.coApplicantMiddleName} ${formData.coApplicantLastName}` }));
  }, [formData.coApplicantFirstName, formData.coApplicantMiddleName, formData.coApplicantLastName]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantSdwOf: formData.coApplicantFatherName }));
  }, [formData.coApplicantFatherName]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantPhone: formData.coApplicantMobile }));
  }, [formData.coApplicantMobile]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantDob: formData.coApplicantDOB }));
  }, [formData.coApplicantDOB]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantNationality: formData.coApplicantNationality }));
  }, [formData.coApplicantNationality]);



  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantProfession: formData.coApplicantProfession }));
  }, [formData.coApplicantProfession]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantEmailId: formData.coApplicantEmail }));
  }, [formData.coApplicantEmail]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantAadharNumber: formData.coApplicantAadhaarNumber }));
  }, [formData.coApplicantAadhaarNumber]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantAddress: formData.coApplicantAddress }));
  }, [formData.coApplicantAddress]);


  //BOOKING DETAILS

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, area: formData.area }));
  }, [formData.area]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, unitNo: formData.unitNo }));
  }, [formData.unitNo]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, plc: formData.PLCs }));
  }, [formData.PLCs]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, paymentPlan: formData.paymentPlan }));
  }, [formData.paymentPlan]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, plcAmount: formData.PLCsValue }));
  }, [formData.PLCsValue]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, registrationAmount: formData.registrationAmount }));
  }, [formData.registrationAmount]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, totalCost: formData.totalCost }));
  }, [formData.totalCost]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, modeOfPayment: formData.paymentMethod }));
  }, [formData.paymentMethod]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, transactionId: formData.transactionID }));
  }, [formData.transactionID]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, chequeDDno: formData.chequeNo }));
  }, [formData.chequeNo]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, chequeDDdate: formData.chequeDate }));
  }, [formData.chequeDate]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, bankName: formData.chequeDetails }));
  }, [formData.chequeDetails]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, applicantPhoto: profilePic }));
  }, [profilePic]);

  useEffect(() => {
    setFormData3(prevFormData3 => ({ ...prevFormData3, coApplicantPhoto: profilePic2 }));
  }, [profilePic2]);


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(formatDateee(new Date()));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      for (const key in formData3) {
        if (formData3[key] !== null) {
          formDataToSend.append(key, formData3[key]);
        }
      }
      const url = `${apiUrl}/letter/addAllotmentLetter?=&applicantId=${empid}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      }

      setFormData3(initialFormData3);
      toast.success(response2.message);
      navigate(`/Inventory-details/${empid}`);
      // navigate("/company-list");
    } catch (error) {
      toast.error(error.message);

    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const Token = localStorage.getItem('Token');
      try {
        const url = `${apiUrl}/applicant/getApplicantById/${empid}`;
        const result = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        });

        const { data } = await result.json();

        setProfilePic(data.applicantImage);
        setProfilePic2(data.coApplicantImage);

        setFormData((prevFormData) => ({
          ...prevFormData,
          ...data,
        }));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUser();
  }, [apiUrl, empid]);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData3((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div className="page">

      <TopHeader />
      <Prince />
      <div style={{ background: "white", margin: 0, padding: "50px 0" }}>
        <table
          align="center"
          width={800}
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{
            background: "#f7f7f7",
            fontFamily: '"Poppins", sans-serif',
            fontSize: 13,
            borderRadius: 15
          }}
        >
          <tbody>
            <tr>
              <td colSpan={3} style={{ height: 20 }}>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td style={{ textAlign: "center" }}>
                <a href="">
                  <img border={0} width={150} src="assets/images/amrealty_logo.png" alt='' />
                </a>
                <br />
                <h2>AM Heritage City-3 Phase-2</h2>
                <h3>
                  Allotment Under INDIA’S SMART CITY &amp; TOURISM DEVELOPMENT PLAN,
                  (ISCTDP)
                  <br /> PHULERA/SAMBHAR/SHAHPURA (भारतीय स्मार्ट शहर व पर्यटनविकास
                  योजना (आई.एस.सी.टी.डी.पी.) फुलेरा /
                </h3>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td style={{ height: 20 }}>
                <hr />
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td style={{ textAlign: "center" }}>
                <p style={{ marginTop: 0 }}>
                  8th Floor, Magnus Tower, Sector-73, Noida- 201307 Up
                  <br />
                  Website:- www.amrealtysolutions.com, Email:-
                  info@amrealtysolutions.com
                </p>
                <h3 style={{ margin: 0 }}>Personal and Booking Details</h3>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td style={{ height: 20 }}>
                <hr />
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td>
                <table
                  align="center"
                  width="100%"
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                >
                  <tbody>
                    <tr>
                      <td style={{ height: 30 }}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span
                          style={{
                            background: "#0158a9",
                            color: "#fff",
                            padding: "7px 15px",
                            borderRadius: 5
                          }}
                        >
                          APPLICANT
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: 30 }} />
                    </tr>
                    <tr>
                      <td>
                        <table
                          align="center"
                          width="100%"
                          border={0}
                          cellSpacing={0}
                          cellPadding={0}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>Name :</span>
                                <br />
                                <input
                                  type="text"
                                  value={`${formData.applicantFirstName} ${formData.applicantMiddleName} ${formData.applicantLastName}`}
                                  style={{
                                    border: "1px solid #cdcdd7",

                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150

                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>S/W/DO. :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantFatherName}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Photo :</span>
                                <br />
                                <input
                                  type="file"
                                  src={profilePic}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 4,
                                    borderRadius: 5,
                                    background: "#fff",
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Phone/Mobile :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantMobile}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Date of Birth :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantDOB}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Nationality :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantNationality}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>Pan No. :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantPAN}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Profession :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantProfession}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Email Id :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantEmail}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>Aadhaar No :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantAadhaarNumber}
                                  style={{
                                    border: "1px solid #cdcdd7",

                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td colSpan={2}>
                                <span style={{ color: "#847f7f" }}>Address :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.applicantAddress}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: 30 }} />
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: 30 }} />
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span
                          style={{
                            background: "#0158a9",
                            color: "#fff",
                            padding: "7px 15px",
                            borderRadius: 5
                          }}
                        >
                          CO-APPLICANT
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: 30 }} />
                    </tr>
                    <tr>
                      <td>
                        <table
                          align="center"
                          width="100%"
                          border={0}
                          cellSpacing={0}
                          cellPadding={0}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>Name :</span>
                                <br />
                                <input
                                  type="text"
                                  value={`${formData.coApplicantFirstName} ${formData.coApplicantMiddleName} ${formData.coApplicantLastName}`}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>S/W/DO. :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantFatherName}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Photo :</span>
                                <br />
                                <input
                                  type="file"
                                  src={profilePic2}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 4,
                                    borderRadius: 5,
                                    background: "#fff",
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Phone/Mobile :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantMobile}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Date of Birth :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantDOB}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Nationality :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantNationality}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>Profession :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantProfession}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Email Id :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantEmail}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Aadhaar No :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantAadhaarNumber}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <span style={{ color: "#847f7f" }}>Address :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.coApplicantAddress}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: 50 }} />
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span
                          style={{
                            background: "#0158a9",
                            color: "#fff",
                            padding: "7px 15px",
                            borderRadius: 5
                          }}
                        >
                          BOOKING DETAILS
                        </span>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: 30 }} />
                    </tr>
                    <tr>
                      <td>
                        <table
                          align="center"
                          width="100%"
                          border={0}
                          cellSpacing={0}
                          cellPadding={0}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Development Charge :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  name="developmentCharges"
                                  value={formData3.developmentCharges}
                                  onChange={handleChange}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Area :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.area}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Unit No :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.unitNo}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>PLC :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.PLCs}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Payment Plan :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.paymentPlan}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>PLC Amount :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.PLCsValue}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Registration Amount :
                                </span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.registrationAmount}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                              <td>
                                <span style={{ color: "#847f7f" }}>Total Cost :</span>
                                <br />
                                <input
                                  type="text"
                                  value={formData.totalCost}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "#847f7f" }}>
                                  Mode Of Payment :
                                </span>
                                <br />
                                <select
                                  className="form-control select select2"

                                  value={formData.paymentMethod}
                                  style={{
                                    border: "1px solid #cdcdd7",
                                    padding: 7,
                                    borderRadius: 5,
                                    width: 150
                                  }}
                                >
                                  <option value=''>Select</option>
                                  <option value={'Cash'}>Cash</option>
                                  <option value={'Online'}>Online</option>
                                  <option value={'Cheque'}>Cheque</option>
                                  <option value={'POS'}>POS</option>

                                </select>
                              </td>
                              {formData.paymentMethod === 'Cash' && (
                                <td>
                                  <span style={{ color: "#847f7f" }}>
                                    Remark :
                                  </span>
                                  <br />
                                  <input
                                    type="text"

                                    style={{
                                      border: "1px solid #cdcdd7",
                                      padding: 7,
                                      borderRadius: 5,
                                      width: 150
                                    }}
                                  />
                                </td>
                              )}
                              {formData.paymentMethod === 'Online' && (
                                <td>
                                  <span style={{ color: "#847f7f" }}>
                                    Transaction ID :
                                  </span>
                                  <br />
                                  <input
                                    type="text"
                                    value={formData.transactionID}
                                    style={{
                                      border: "1px solid #cdcdd7",
                                      padding: 7,
                                      borderRadius: 5,
                                      width: 150
                                    }}
                                  />
                                </td>

                              )}

                              {formData.paymentMethod === 'POS' && (
                                <td>
                                  <span style={{ color: "#847f7f" }}>
                                    Transaction ID :
                                  </span>
                                  <br />
                                  <input
                                    type="text"
                                    value={formData.transactionID}
                                    style={{
                                      border: "1px solid #cdcdd7",
                                      padding: 7,
                                      borderRadius: 5,
                                      width: 150
                                    }}
                                  />
                                </td>

                              )}

                              {formData.paymentMethod === 'Cheque' && (
                                <>
                                  <td>
                                    <span style={{ color: "#847f7f" }}>
                                      Cheque No :
                                    </span>
                                    <br />
                                    <input
                                      type="text"
                                      value={formData.chequeNo}
                                      style={{
                                        border: "1px solid #cdcdd7",
                                        padding: 7,
                                        borderRadius: 5,
                                        width: 150
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <span style={{ color: "#847f7f" }}>
                                      Cheque Details: Bank :
                                    </span>
                                    <br />
                                    <input
                                      type="text"
                                      value={formData.chequeDetails}
                                      style={{
                                        border: "1px solid #cdcdd7",
                                        padding: 7,
                                        borderRadius: 5,
                                        width: 150
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <span style={{ color: "#847f7f" }}>
                                      Cheque Date :
                                    </span>
                                    <br />
                                    <input
                                      type="date"
                                      value={formData.chequeDate}

                                      style={{
                                        border: "1px solid #cdcdd7",
                                        padding: 7,
                                        borderRadius: 5,
                                        width: 150
                                      }}
                                    />
                                  </td>

                                </>
                              )}

                            </tr>
                            <tr>
                              <td style={{ height: 20 }} colSpan={3} />
                            </tr>
                            <tr>


                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ height: 20 }} />
            </tr>
            <tr>
              <td colSpan={3} align="center">
                <h3>Term &amp; Condition</h3>
              </td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td align="left" style={{ fontSize: 12 }}>
                <ul style={{ margin: 0 }}>
                  <li>
                    The Intending Buyer has applied for the registration of Plot/Farm
                    House with full knowledge and subject to all laws, notifications
                    and rules applicable to this area, which have been explained by
                    the company and understood by him/her.
                  </li>
                  <li>
                    The intending buyer has fully satisfied himself/herself about the
                    interest and title of the company in the land, understands all
                    limitations and obligations in respect of it and does not have any
                    objection.
                  </li>
                  <li>
                    For preferential situated Plot/Farm House, extra charges as
                    applicable will be payable by the intending buyer.
                  </li>
                  <li>
                    The cost of Development Charges for a Plot/Farm House whatever is
                    applicable will be payable by the customer.
                  </li>
                  <li>
                    If any applicant wants to cancel his/her registration once they
                    have applied for a Plot/Farm House under this scheme, then the
                    company will refund his/her registration money after deducting 50%
                    of the total cost of Unit/s.
                  </li>
                  <li>
                    The applicant has to pay the maintenance &amp; Security charges as
                    applicable.
                  </li>
                  <li>
                    In case if any client fails to pay the maintenance &amp; security
                    charges on time, then the company will not be responsible for the
                    security of his/her property or not be responsible if anyone
                    encroaches his/her property and the client cannot blame the
                    company for any loss what so ever. Moreover the company will not
                    provide the maintenance services for common area to such clients.
                  </li>
                  <li>
                    The company reserves the right to cancel the
                    registration/allotment of the Plot/Farm House in case if any
                    cheque for the Registration Amount or the Balance Amount for that
                    Plot/Farm House bounced/dishonored due to customer’s fault.
                  </li>
                  <li>
                    The applicant/allotee shall before taking possession of the
                    residential Plot/Farm House, must clear all the dues towards the
                    residential Plot/Farm House and have the Conveyance Deed for the
                    said residential Plot/Farm House executed in his favour by the
                    Company after paying stamp duty, registration fee and other
                    charges/expenses.
                  </li>
                  <li>
                    The company will start giving facilities like concrete road, water
                    supply &amp; Electricity only when at least 10% of allotees will
                    make their houses in the township.
                  </li>
                  <li>
                    The company can change its scheme at any time and the applicants
                    are not supposed to show any objection on the same.
                  </li>
                  <li>
                    It is made clear that it is not possible to check the eligibility
                    of applicant at the time of acceptance of the Registration form.
                    Therefore, those who are not eligible would register their name at
                    their own risk and wouldn’t be entitled for allotment of Plot/Farm
                    Houses if at a later stage it is detected that they are not
                    eligible under the scheme.
                  </li>
                  <li>
                    All allotments shall be made on free hold basis. However, the
                    title shall be transferred only when Sale Deed/Registry is
                    executed in favour of the allotee and is registered in the office
                    of Sub-Registrar.
                  </li>
                  <li>
                    The Company reserves the right to alter/amend/modify any of the
                    terms &amp; conditions of application eligibility and allotment at
                    its sole discretion or as desired/directed by the Govt. of the
                    State/India. The consequences of such alteration/amendment/
                    modification will be automatically binding on all applicants,
                    without any further reference of them.
                  </li>
                  <li>
                    If any misrepresentation/concealment/suppression of material facts
                    are found to be made by the applicant/allotee, then the allotment
                    of the given Plot/Farm House will be cancelled at the immediate
                    effect and the total amount of the Plot/Farm House will be
                    refunded after deducting 50% of the total Cost..
                  </li>
                  <li>
                    Cheque/DD/Payorder to be in the name of AM Realty Solutions.
                  </li>
                  <li>
                    Cheque bouncing will be charged Rs.1000/- as penalty, and delay in
                    payment at 18% interest.
                  </li>
                  <li>
                    All provisional allotments under Tourism City Development Plan is
                    around 30-35% less cost than Original Price. In Case of Payment
                    Delay in any payment plan for more than 45 days, the allotment
                    will be cancelled from Tourism City Development plan and Original
                    price of Plot/Farm House shall be paid by the applicant/allotee.
                  </li>
                  <li>
                    Possession will be given within 24 months after booking. In case
                    possession is not given, 18% interest will be paid to the buyer on
                    the booking amount.
                  </li>
                  <li>
                    All PDC Cheques, according to the Payment Plan selected by The
                    Client till Registry, need to be deposited at the time of
                    agreement and gift dispersal in Noida Office
                  </li>
                </ul>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ height: 20 }}>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td colSpan={3} align="center">
                <h3>Declaration</h3>
              </td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td>
                <ul style={{ margin: 0 }}>
                  <li>
                    I/WE declare that I/We have read &amp; understood the above
                    mentioned terms and conditions of the project and shall abide by
                    them.
                  </li>
                  <li>
                    The Plot/Farm Houses allotted to me by the company under the rules
                    shall be acceptable to me/us. I/We shall have no objection.
                  </li>
                  <li>
                    In case of cancellation of registration done by me/us. I/We shall
                    accept the deduction made by the company as per rules.
                  </li>
                  <li>
                    I agree that the measurement/number and area of Plot/Farm House
                    required by me/us can vary at the time of Registry as per the
                    Govt. Rules/Approved Map and Availability.
                  </li>
                  <li>
                    I/We hereby declare that all information on the application form
                    has been given by me/us are true to the best of knowledge and
                    belief.{" "}
                  </li>
                </ul>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ height: 70 }}>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td>
                <table
                  align="center"
                  width="100%"
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                >
                  <tbody>
                    <tr>
                      <td>
                        <h4>{currentDate}</h4>
                      </td>
                      <td align="right">
                        <h4>Signature</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ height: 20 }}>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style={{ width: 50 }}>&nbsp;</td>
              <td align="center">
                <p>
                  Your receipt and acknowledgment will be delivered to you within 15
                  days from the date of allotement.in case of delay, contact Amrealty
                  office
                </p>
              </td>
              <td style={{ width: 50 }}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ height: 20 }}>
                &nbsp;
              </td>
            </tr>
          </tbody>


          <button
            style={buttonStyle}
            onClick={handleSubmit}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#45a049';
              e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#4CAF50';
              e.currentTarget.style.transform = 'scale(1)';
            }}
          >
            Submit
          </button>

        </table>

      </div>

    </div>
  )
}

export default AllotmentLetter